import React, { useEffect, useState } from 'react'
import './TextWithBackGround.css'

const TextWithBackGround = (props: any) => {

    const [data, setData] = useState<any>(null)
    const [rgbColor, setRgbColor] = useState<any>(null)
    const [rgbaColor, setRgbaColor] = useState<any>(null)

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    const hexToRgb = (hex: any) => {
        const bigint = parseInt(hex?.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };

    useEffect(() => {
        if(props.data) {
            handleParse();
        }
    }, [props.data])
    
    useEffect(() => {
        if(data) {
            setRgbColor(hexToRgb(data?.color));
        }
    }, [data])

    useEffect(() => {
        setRgbaColor(`rgba(${rgbColor}, ${0.10})`);
    }, [rgbColor])

    return (
        <b 
            className='textWithBackGround-text small'
            style={{
                color: data?.color,
                backgroundColor: rgbaColor
            }}
        >{data?.h1 ? data?.h1 : data}</b>
    );
}

export default TextWithBackGround;