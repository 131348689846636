import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './PendingPolizas.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useNavigate } from 'react-router-dom';

const PendingPolizas = (props: any) => {

    const navigate = useNavigate();

    return (
        <div className='polizas-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='polizas-container-main'>
                <div className='polizas-container-main-hedear'>
                    <div className='polizas-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <GenericTableAndFilters
                    data={{
                        search: 'insurance_policy_pending',
                        type: 'insurance_policies_pending',
                        title: 'Coberturas Pendientes',
                        createPath: '/insurance_policies/create',
                        createTitle: 'CREAR COBERTURA',
                    }}
                    goToDetails={(id: any) => navigate('/insurance_policies/' + id + '#Detalle%20de%20cobertura')}
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies_pending:download' }).length > 0}
                    canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies_pending:create' }).length > 0}
                />
            </div>
        </div>
    );
}

export default PendingPolizas;