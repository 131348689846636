import axios from './instanceActions'
import { 
    netUserMine, 
    netSections
} from "../net/Connector";
import Cookies from 'universal-cookie';

export const actionUserMine = (setUserData: any, setUserList: any, setPrincipalList: any) => {
    const cookies = new Cookies();
    axios.get(netUserMine())
        .then((response) => {
            setUserData(response.data)
            cookies.set('countryId', response.data.countryId);
            axios.get(netSections())
                .then((response) => {
                    const res = response?.data?.sort((a: any, b: any) => a.order - b.order);
                    setUserList(res.filter((a: any) => a.type === 'USER'))
                    setPrincipalList(res.filter((a: any) => a.type === 'PRINCIPAL'))
                })
                .catch((error) => {
                })
        })
        .catch((error) => {
        })
};