import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasItem.css'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';

const PolizasItem = (props: any) => {

    const [itemData, setPolizaData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
    }, [])

    return (
        <div className='polizasItem-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasItem-container-data'>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={itemData?.productPolicyDTO?.categoryName}
                            disabled
                        />
                        <CustomTextInput
                            label='MARCA'
                            value={itemData?.productPolicyDTO?.subCategoryName}
                            disabled
                        />
                        <CustomTextInput
                            label='PRODUCTO'
                            value={itemData?.productPolicyDTO?.name + ' ' + itemData?.productPolicyDTO?.model}
                            disabled
                        />
                        <CustomTextInput
                            label='PRECIO'
                            value={itemData?.productPolicyDTO?.productValue}
                            disabled
                        />
                    </div>
                    <p className='polizasItem-container-data-label bold medium'>ADJUNTOS</p>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            value='Imagen.jpg'
                            disabled
                            img={itemData?.productPolicyDTO?.image}
                        />
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasItem;