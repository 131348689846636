import React, { useEffect, useState } from 'react'
import './GenericItem.css'

const GenericItem = (props: any) => {

    return (
        <div 
            onClick={() => props.setItemSelected(props.data)}
            className='genericItem-container-item'
            style={{ backgroundColor: props.itemSelected?.label == props.label ? 'var(--primary500)' : 'var(--primary200)' }}
        >
            <p 
                className='genericItem-container-item-label regular small'
                style={{ color: props.itemSelected?.label == props.label ? 'var(--white)' : 'var(--gray700)' }}
            >{props.label?.toUpperCase()}</p>
        </div>
    );

}

export default GenericItem;