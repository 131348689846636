import axios from './instanceActions'
import {
    netPolizaData, 
    netPolizaDataFromClaim,
    netCheckoutData
} from "../net/Connector";

export const getPolizaData = (userId: any, setPolizaData: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = netPolizaData(userId)
    if(from === 'claim') {
        url = netPolizaDataFromClaim(userId)
    }
    axios.get(url)
        .then((response) => {
            setPolizaData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getCheckoutData = (id: any, setCheckoutData: any) => {
    axios.get(netCheckoutData(id))
        .then((response) => {
            setCheckoutData(response.data)
        })
        .catch((error) => {
        })
}