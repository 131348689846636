import React, { useEffect, useState } from 'react'
import { 
    getUserClientDataWeco
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './UserData.css'
import Moment from 'moment/moment'
import Lottie from 'react-lottie'
import loadingLottie from '../../../assets/lotties/loading.json'

const UserData = (props: any) => {

    const [userClientData, setUserClientData] = useState<any>(null)

    const [generoList, setGeneroList] = useState<any>(null)
    const [civilList, setCivilList] = useState<any>(null)
    const [provinciaList, setProvinciaList] = useState<any>(null)
    const [localidad, setLocalidad] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getUserClientDataWeco(props.userId, setUserClientData, setGeneroList, setCivilList, setLocalidad, setProvinciaList, props.from, setLoading)
    }, [])


    return (
        <div className='userData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='userData-container-data'>
                    <div className='userData-container-data-section'>
                        <CustomTextInput
                            label='FECHA DE CREACIÓN'
                            value={Moment(userClientData?.creationDate).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='ID'
                            value={userClientData?.id}
                            disabled
                        />
                        <div className='userData-container-data-section-status'>
                            <p className='userData-container-data-section-status-label bold medium'>PERFIL</p>
                            <p 
                                className='userData-container-data-section-status-data regular small'
                                style={{
                                    backgroundColor: userClientData?.profileCompleted ? 'var(--accent300)' : 'var(--accent500)'
                                }}
                            >
                                {userClientData?.profileCompleted ? 'COMPLETO' : 'INCOMPLETO'}
                            </p>
                        </div>
                    </div>
                    <h5 className='userData-container-data-title bold'>Datos personales</h5>
                    <div className='userData-container-data-section'>
                        <CustomTextInput
                            label='NOMBRE'
                            value={userClientData?.name}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='APELLIDO'
                            value={userClientData?.lastName}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='DNI'
                            value={userClientData?.documentNumber}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='GÉNERO'
                            value={generoList?.filter((a: any) => a.codigo === userClientData?.sexId)[0]?.label}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='FECHA DE NACIMIENTO'
                            value={Moment(userClientData?.birthDate).format('DD/MM/YYYY')}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='CUIL/CUIT'
                            value={userClientData?.cuil}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='ESTADO CIVIL'
                            value={civilList?.filter((a: any) => a.codigo == userClientData?.civilStatusId)[0]?.label}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='DIRECCIÓN'
                            value={userClientData?.addressStreet}
                            disabled
                            required
                        />
                        <div className='userData-container-data-section-double'>
                            <CustomTextInput
                                label='PISO/DEPTO'
                                value={userClientData?.addressFloor}
                                disabled
                            />  
                            <CustomTextInput
                                label='CÓDIGO POSTAL'
                                value={userClientData?.addressPostalCode}
                                disabled
                                required
                            />
                        </div>
                        <CustomTextInput
                            label='LOCALIDAD'
                            value={localidad}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='PROVINCIA'
                            value={provinciaList ? provinciaList[userClientData?.provinciaId - 1]?.provincia : ''}
                            disabled
                            required
                        />
                        <div className='userData-container-data-section-double'>
                            <CustomTextInput
                                label='CÓD. DE ÁREA'
                                value='+54'
                                disabled
                                required
                            />  
                            <CustomTextInput
                                label='CELULAR'
                                value={userClientData?.phone}
                                disabled
                                required
                            />
                        </div>
                    </div>
                    <h5 className='userData-container-data-title bold'>Datos de cuenta</h5>
                    <div className='userData-container-data-section'>
                        <CustomTextInput
                            label='EMAIL'
                            value={userClientData?.username}
                            disabled
                            required
                        />
                    </div>
                </div>
            }
        </div>
    );

}

export default UserData;