import React, { useEffect, useRef, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './CrearPoliza.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import { countries } from '../../data';
import { useNavigate } from 'react-router-dom';

const CrearPoliza = (props: any) => {

    const navigate = useNavigate();
    const iframeRef: any = useRef();
    const [appBaseIframe] = useState<any>(process.env.REACT_APP_IFRAME);
    const [appBaseIframePath] = useState<any>(process.env.REACT_APP_IFRAME_PATH);
    const [iframeState, setIframeState] = useState<any>(0);

    const sendAdminToken = () => {
        if(window.location.hostname == 'localhost') {
            iframeRef.current.contentWindow.postMessage(
                props.userAccessToken,
                'http://localhost:3001'
            )
        } else {
            iframeRef.current.contentWindow.postMessage(
                props.userAccessToken,
                appBaseIframe
            )
        }
    }

    const getIframeUrl = () => {
        const path = appBaseIframePath + '?admin=True';
        const country = countries.find(c => c.id === props.userData?.countryId)?.url
        if(window.location.hostname == 'localhost') {
            return "http://localhost:3001" + country + path
        } else {
            if (country && path) {
                return appBaseIframe + country + path
            } else if (country) {
                return appBaseIframe + country
            } else if (path) {
                return appBaseIframe + path
            } return appBaseIframe
        }
    }

    const recieveMessageFromChild = () => {
        window.addEventListener("message", (event) => {
            if(window.location.hostname == 'localhost') {
                if (event.origin !== "http://localhost:3001") return;
                if (event.data === '/dashboard' || event.data === '/insurance_policies') {
                    navigate(event.data)
                } else {
                    sendAdminToken();
                }
            } else {
                if (event.origin !== appBaseIframe) return;
                if (event.data === '/dashboard' || event.data === '/insurance_policies') {
                    navigate(event.data)
                } else {
                    sendAdminToken();
                }
            } return;
        }, false);
    }

    useEffect(() => {
        setIframeState(iframeState + 1);
        if(props.userAccessToken) {
            recieveMessageFromChild();
        }
    }, [props.userAccessToken])

    return (
        <div className='crearPoliza-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='crearPoliza-container-main'>
                <div className='crearPoliza-container-main-hedear'>
                    <div className='crearPoliza-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='crearPoliza-container-main-main'>
                    <p className='crearPoliza-container-main-main-title'>Crear cobertura</p>
                    <div className='crearPoliza-container-main-main-goBack' onClick={() => navigate('/insurance_policies')}>
                        <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 16 }}>arrow_back_ios_new</span>
                        <b className='crearPoliza-container-main-main-goBack-text medium'>Volver a Coberturas</b>
                    </div>
                    <iframe key={iframeState} title='' src={getIframeUrl()} ref={iframeRef} className='crearPoliza-container-main-main-iframe'></iframe>
                </div>
            </div>
        </div>
    );
}

export default CrearPoliza;