import React, { useEffect, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './PolizasDetails.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import Headers from '../../components/Headers/Headers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserData from '../../components/UserDatailsComponents/UserData/UserData';
import PolizasData from '../../components/PolizasDetailsComponents/PolizasData/PolizasData';
import PolizasItem from '../../components/PolizasDetailsComponents/PolizasItem/PolizasItem';

const PolizasDetails = (props: any) => {

    const [headerListSelected, setHeaderSelectedList] = useState<string>('')
    const { hash }: any = useLocation();
    const { id }: any = useParams();
    const navigate = useNavigate();

    const headerList = [
        {
            name: 'Detalle de cobertura',
            click: () => {
                setHeaderSelectedList('Detalle de cobertura')
                navigate('#Detalle de cobertura')
            },
            component: <PolizasData polizaId={id}/>
        },
        {
            name: 'Item',
            click: () => {
                setHeaderSelectedList('Item')
                navigate('#Item')
            },
            component: <PolizasItem polizaId={id}/>
        },
        {
            name: 'Usuario',
            click: () => {
                setHeaderSelectedList('Usuario')
                navigate('#Usuario')
            },
            component: <UserData userId={id} from='poliza'/>
        }
    ]

    useEffect(() => {
        setHeaderSelectedList(hash.substring(1).replace(/%20/g, " "))
    }, [hash])

    return (
        <div className='polizasDetails-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='polizasDetails-container-main'>
                <div className='polizasDetails-container-main-hedear'>
                    <div className='polizasDetails-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='polizasDetails-container-main-body'>
                    <h2 className='polizasDetails-container-main-body-title bold'>Ver cobertura</h2>
                    <div className='polizasDetails-container-main-body-volver' onClick={() => navigate('/insurance_policies')}>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976318 7.31658 -0.0976317 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976312 7.31658 -0.0976311 7.70711 0.292893Z" fill="var(--primary500)"/>
                        </svg>
                        <p className='polizasDetails-container-main-body-volver-text bold small'>&nbsp;&nbsp;Volver a Coberturas</p>
                    </div>
                    <Headers 
                        list={headerList}
                        selected={headerListSelected}
                    />
                    {headerList?.filter((a: any) => a.name === headerListSelected)[0]?.component}
                </div>
            </div>
        </div>
    );
}

export default PolizasDetails;