import React, { useEffect, useState } from "react";
import "./DateBetweenComponent.css";
import Moment from "moment/moment";

const DateBetweenComponent = (props: any) => {
  const [localFecha, setLocalFecha] = useState<string>(() => {
    const storedValue = sessionStorage.getItem(props.data?.text);
    return storedValue === null ? "" : JSON.parse(storedValue);
  });
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const validDates = () => {
    return Moment(localFecha, "DD/MM/YYYY", true).isValid();
  };

  const handleDateInput = (text: string) => {
      let newText: any = text;
      if (text.length >= 3 && text[2] !== "/") {
        newText = text[0] + text[1] + "/" + text.slice(2);
      } else if (text.length >= 6 && text[5] !== "/") {
        newText =
          text[0] + text[1] + text[2] + text[3] + text[4] + "/" + text.slice(5);
      }
      setLocalFecha(newText);
  };

  const updateObject = (originalObject: any, inputValue: any) => {
    const updatedObject = { ...originalObject };
    // Loop through the originalObject and its nested properties
    const recursiveReplace = (obj: any) => {
      for (const prop in obj) {
        if (typeof obj[prop] === "object") {
          recursiveReplace(obj[prop]); // Recursively search for nested properties
        } else if (obj[prop] === "$VALOR") {
          obj[prop] = inputValue; // Replace $VALOR with the actual inputValue
        } else {
          obj[prop] = inputValue;
        }
      }
    };

    recursiveReplace(updatedObject);

    return updatedObject;
  };

  useEffect(() => {
    if (validDates() && props.data.text==='Desde') {
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(localFecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .startOf("day")
            .toISOString()
        )
      );
    }
    if (validDates() && props.data.text==='Hasta') {
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(localFecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .endOf("day")
            .toISOString()
        )
      );
    }
  }, [localFecha]);

  return (
    <div className="DateBetweenComponent-container">
      <p className="DateBetweenComponent-container-text regular small">
        {props.data.text}
      </p>
      <input
        id={props.data.text}
        className={
          inputFocus
            ? "DateBetweenComponent-container-inputFocus regular medium"
            : "DateBetweenComponent-container-input light medium"
        }
        placeholder="DD/MM/AAAA"
        onChange={(e: any) => handleDateInput(e.target.value)}
        value={localFecha}
        maxLength={10}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        style={{
          border: inputFocus
            ? "1px solid var(--principalPrimario)"
            : localFecha.length === 0 || validDates()
            ? "1px solid var(--gray300)"
            : "1px solid var(--errorPrimary)",
        }}
        defaultValue={localFecha !== "" ? localFecha : undefined}
      />
      {localFecha.length === 0 ||
        (!validDates() && (
          <p className="DateBetweenComponent-container-error regular small">
            Formato inválido.
          </p>
        ))}
    </div>
  );
};

export default DateBetweenComponent;
