import React, { useEffect, useState } from 'react'
import './DetalleItemCard.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'
import GenericItem from '../../GenericItem/GenericItem';
import GrouperIntereses from '../GrouperIntereses/GrouperIntereses';
import FunnelMetrics from '../FunnelMetrics/FunnelMetrics';

const DetalleItemCard = (props: any) => {

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }

    const getWidthSection = () => {
        switch (props.data[1]) {
            case null:
                return '100%'
            case 'grouper':
                return '31%'
            case 'collection':
                return '69%'
            default:
                return '100%'
        }
    }

    const detalleItemCardMethods: any = {
        "single": {
            name: "detalleItemCardMethods.single",
            methodComponent: GenericItem,
        },
        "grouper": {
            name: "detalleItemCardMethods.grouper",
            methodComponent: GrouperIntereses,
        },
        "collection": {
            name: "detalleItemCardMethods.collection",
            methodComponent: FunnelMetrics,
        }
    }

    const CardComponent: any = detalleItemCardMethods[props.data[1]].methodComponent;

    return (
        <div className='detalleItemCard-container' style={{width: getWidthSection()}}>
            <b className='detalleItemCard-container-title bold large'>{props.data[3]}</b>
            {props.data[1] === "grouper" || props.data[1] === "collection" ?
                <div className={props.data[1] === 'collection' ? 'detalleItemCard-container-groupList funnel' : 'detalleItemCard-container-groupList'}>
                    {JSON.parse(props.data[6])?.map((a: any, index: any) => (
                        <CardComponent
                            key={index}
                            label={a.label}
                            setItemSelected={(e: any) => props.setItemSelected(e)}
                            itemSelected={props.itemSelected}
                            data={a}
                            index={index}
                        />
                    ))}
                </div>
                :
                <div className='detalleItemCard-container-list'>
                    {JSON.parse(props.data[6])?.map((a: any, index: any) => (
                        <div key={index} className='detalleItemCard-container-list-item'>
                            <CardComponent
                                label={a.label}
                                setItemSelected={(e: any) => props.setItemSelected(e)}
                                itemSelected={props.itemSelected}
                                data={a}
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    );

}

export default DetalleItemCard;