import axios from './instanceActions'
import {
    netClaimsData,
    netCommentClaim,
    netStatusChangeClaim
} from "../net/Connector";

export const getClaimsData = (claimId: any, setClaimsData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netClaimsData(claimId))
        .then((response) => {
            setClaimsData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const postStatusChangeClaim = (reportId: string, message: any, status: any, setCurrentEstado: any) => {
    const formData = new FormData();
    formData.append('message', message);
    formData.append('reclamoId', reportId);
    axios({
        url: netCommentClaim(),
        method: 'post',
        data: formData
    })
        .then((response) => {
            const formData = new FormData();
            formData.append('reportId', reportId);
            formData.append('status', status.label);
            axios({
                url: netStatusChangeClaim(),
                method: 'post',
                data: formData
            })
                .then((response) => {
                    setCurrentEstado(status)
                })
                .catch((error) => {
                })
        })
        .catch((error) => {
        })
};