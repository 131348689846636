import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './CarPrices.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';

const CarPrices = (props: any) => {

    return (
        <div className='carPrices-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='carPrices-container-main'>
                <div className='carPrices-container-main-hedear'>
                    <div className='carPrices-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'cars:download' }).length > 0}
                    data={{
                        type: 'car_prices',createPath: '/car_prices/create',
                        title: 'Cotización de Automóvil',
                        createTitle: 'CREAR Automovil',
                        search: 'car_prices',
                    }} canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'cars:create' }).length > 0}
                />
            </div>
        </div>
    );
}

export default CarPrices;