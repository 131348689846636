import React, { useEffect, useState } from 'react'
import { 
    getClaimsData,
    getPolizaData,
    postStatusChangeClaim
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './ClaimsData.css'
import DownloadIcon from '../../../assets/images/svgs/downloadIcon.svg';
import { ReactSVG } from 'react-svg'
import Modal from 'react-modal';
import EstadoModal from '../../Modals/EstadoModal/EstadoModal';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/exito.json'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'

const ClaimsData = (props: any) => {

    const [claimData, setClaimData] = useState<any>(null)
    const [downloading, setDownloading] = useState<boolean>(false)
    const [currentEstado, setCurrentEstado] = useState<any>(null)
    const [estadoSelected, setEstadoSelected] = useState<any>(null)
    const [showEstadoModal, setShowEstadoModal] = useState<boolean>(false)
    const [polizaData, setPolizaData] = useState<any>(null)
    const [statusText, setStatusText] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const estados = [
        {
            'id': 1,
            'label': 'CREATED',
            'name': 'Iniciado',
            'color': 'var(--pendingPrimary)'
        },
        {
            'id': 2,
            'label': 'IN_PROCESS',
            'name': 'En proceso',
            'color': 'var(--warningPrimary)'
        },
        {
            'id': 3,
            'label': 'ACCEPTED',
            'name': 'Aceptado',
            'color': 'var(--successPrimary)'
        },
        {
            'id': 4,
            'label': 'CANCELLED',
            'name': 'Rechazado',
            'color': 'var(--errorPrimary)'
        }
    ];

    useEffect(() => {
        getClaimsData(props.claimId, setClaimData, setLoading)
    }, [])

    useEffect(() => {
        if (polizaData) getLagajo();
    }, [polizaData])

    const formatDate = (genericDate: any) => {
        const date = new Date(genericDate);
        const mins = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear()+" "+ date.getHours() + ":" + mins;
      }
    
    const reformatDate = (dateStr: any) => {
        let dArr = dateStr.split("-");  // ex input "2010-01-18"
        return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0]; //ex out: "18/01/2010"
    }

    const getLagajo = () =>  {
        var text = ""
        if(claimData?.user?.documentTypes?.id == '1') {
            text = "DATOS DEL USUARIO \n" +
            "\n" +
            "Nombre del Usuario: " + claimData?.user?.name + " " + claimData?.user?.lastName + "\n" + 
            "Fecha de Alta: " + formatDate(claimData?.user?.createdAt) + " \n" + 
            "Mail: " + claimData?.user?.username + "\n" +
            "Fecha de nacimiento: " + reformatDate(claimData?.user?.birthDate) + "\n" +
            "DNI: " + claimData?.user?.documentNumber + "\n" +
            "Dirección: " + claimData?.user?.addressStreet + " " + claimData?.user?.addressFloor + "\n" +
            "CP: " + claimData?.user?.addressPostalCode + "\n" +
            "Teléfono: " + claimData?.user?.phone + "\n" +
            "Sexo: " + claimData?.user?.sexId + "\n" +
            "\n" +
            "DATOS DE LA DENUNCIA \n" +
            "\n" +
            "Tipo de Reclamo: " + claimData?.tipoReclamo.name + "\n" +
            "Dirección de Denuncia: " + claimData?.addressIncidence + "\n" +
            "Descripción: " + claimData?.description + "\n" +
            "Fecha del Incidente: " + formatDate(claimData?.incidenceDate) + "\n" +
            "\n" +
            "DATOS DEL SEGURO \n" +
            "\n" +
            "Nº Póliza: " + claimData?.productInsuredUser?.nroPoliza + "\n" +
            "Creado: " + formatDate(claimData?.productInsuredUser?.fechaDesde) + "\n" +
            "Producto: " + polizaData?.productPolicyDTO?.name + " " + polizaData?.productPolicyDTO?.model + "\n" +
            "IMEI: " + claimData?.productInsuredUser?.imei + "\n" +
            "Valor del Bien: $" + claimData?.productInsuredUser?.amountPeriodo + "\n" +
            "Valor asegurado: $" + claimData?.productInsuredUser?.amount + "\n" +
            "% Asegurado: " + claimData?.productInsuredUser?.porcentage + "%\n" +
            "Desde: " + formatDate(claimData?.productInsuredUser?.fechaDesde) + "\n" +
            "Hasta: " + formatDate(claimData?.productInsuredUser?.fechaHasta) + "\n" +
            "Tipo Póliza: " + claimData?.productInsuredUser?.periodo + "\n";
          } else {
            text = "\bDATOS DEL USUARIO\b \n" +
            "\n" +
            "Nombre del Usuario: " + claimData?.user?.name + " " + claimData?.user?.lastName + "\n" + 
            "Fecha de Alta: " + claimData?.user?.createdAt + "\n" +
            "Mail: " + claimData?.user?.username + "\n" +
            "Fecha de nacimiento: " + claimData?.user?.birthDate + "\n" +
            "RFC: " + claimData?.user?.documentNumber + "\n" +
            "Dirección: " + claimData?.user?.addressStreet + " " + claimData?.user?.addressFloor + "\n" +
            "CP: " + claimData?.user?.addressPostalCode + "\n" +
            "Teléfono: " + claimData?.user?.phone + "\n" +
            "Sexo: " + claimData?.user?.sexId + "\n" +
            "\n" +
            "DATOS DE LA DENUNCIA \n" +
            "\n" +
            "Tipo de Reclamo: " + claimData?.tipoReclamo.name + "\n" +
            "Dirección de Denuncia: " + claimData?.addressIncidence + "\n" +
            "Descripción: " + claimData?.description + "\n" +
            "Fecha del Incidente: " + claimData?.incidenceDate + "\n" +
            "\n" +
            "DATOS DEL SEGURO \n" +
            "\n" +
            "Nº Póliza: " + claimData?.productInsuredUser?.nroPoliza + "\n" +
            "Creado: " + formatDate(claimData?.productInsuredUser?.fechaDesde) + "\n" +
            "Producto: " + polizaData?.productPolicyDTO?.name + " " + polizaData?.productPolicyDTO?.model + "\n" +
            "IMEI: " + claimData?.productInsuredUser?.imei + "\n" +
            "Tipo Póliza: " + claimData?.productInsuredUser?.periodo + "\n";
        }
        let filename = 'Legajo'
        var element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)
      
        element.style.display = 'none'
        document.body.appendChild(element)
      
        element.click()
      
        document.body.removeChild(element)
    }

    useEffect(() => {
        if(claimData) setCurrentEstado(estados.filter((a) => a.label === claimData?.status)[0])
    }, [claimData])

    const enabled = (id: any) => {
        if(id < currentEstado?.id) return false
        if(id === 3 && currentEstado?.id === 4) return false
        if(id === 4 && currentEstado?.id === 3) return false
        return true
    }

    const EstadoAlert = () => {
        return (
            <Modal
                isOpen={showEstadoModal}
                onRequestClose={() => setShowEstadoModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EstadoModal
                    currentEstado={currentEstado}
                    estadoSelected={estadoSelected}
                    claimData={claimData}
                    statusText={statusText}
                    setStatusText={setStatusText}
                    confirmar={() => {
                        setShowEstadoModal(false)
                        postStatusChangeClaim(claimData?.id, statusText, estadoSelected, setCurrentEstado)
                    }}
                    cancelar={() => setShowEstadoModal(false)}
                />
            </Modal>
        )
    }

    return (
        <div className='claimsData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <>
                    <div className='claimsData-container-data'>
                        <div className='claimsData-container-data-section'>
                            <CustomTextInput
                                label='RECLAMO NRO'
                                value={claimData?.id}
                                disabled
                                tooltiptext='El nro de reclamo es interno y difiere al de la compañía aseguradora.'
                            />
                            <CustomTextInput
                                label='TIPO'
                                value={claimData?.tipoReclamo?.name}
                                disabled
                            />
                            <CustomTextInput
                                label='FECHA DE CREACIÓN'
                                value={Moment(claimData?.createdAt).format('DD/MM/YYYY')}
                                disabled
                            />
                        </div>
                        <div className='claimsData-container-data-section'>
                            <CustomTextInput
                                label='FECHA DEL INCIDENTE'
                                value={Moment(claimData?.incidenceDate).format('DD/MM/YYYY')}
                                disabled
                            />
                            <CustomTextInput
                                label='DIRECCIÓN DEL INCIDENTE'
                                value={claimData?.addressIncidence}
                                disabled
                                maps
                                lat={claimData?.latitudeIncidence}
                                lng={claimData?.longitudeIncidence}
                            />
                        </div>
                        <p className='claimsData-container-data-text bold medium'>DESCRIPCIÓN</p>
                        <p className='claimsData-container-data-textarea regular medium'>{claimData?.description}</p>
                        <p className='claimsData-container-data-text bold medium'>ADJUNTOS</p>
                        {claimData?.reportImages?.length !== 0 && <div className='claimsData-container-data-section' style={{ marginBottom: 0}}>
                            {claimData?.reportImages?.map((a: any) => (
                                <CustomTextInput
                                    value='Imagen.jpg'
                                    disabled
                                    img={a}
                                />
                            ))}
                        </div>}
                        {claimData?.reportImagesBajaImei?.length !== 0 && <div className='claimsData-container-data-section' style={{ marginBottom: 0}}>
                            {claimData?.reportImagesBajaImei?.map((a: any) => (
                                <CustomTextInput
                                    value='Imagen.jpg'
                                    disabled
                                    img={a}
                                />
                            ))}
                        </div>}
                        {claimData?.reportImagesIdentificacion?.length !== 0 && <div className='claimsData-container-data-section' style={{ marginBottom: 0}}>
                            {claimData?.reportImagesIdentificacion?.map((a: any) => (
                                <CustomTextInput
                                    value='Imagen.jpg'
                                    disabled
                                    img={a}
                                />
                            ))}
                        </div>}
                        {claimData?.reportImagesTechnicalDiagnose?.length !== 0 && <div className='claimsData-container-data-section' style={{ marginBottom: 0}}>
                            {claimData?.reportImagesTechnicalDiagnose?.map((a: any) => (
                                <CustomTextInput
                                    value='Imagen.jpg'
                                    disabled
                                    img={a}
                                />
                            ))}
                        </div>}
                        {claimData?.reportesImagesDenuncia?.length !== 0 && <div className='claimsData-container-data-section' style={{ marginBottom: 0}}>
                            {claimData?.reportesImagesDenuncia?.map((a: any) => (
                                <CustomTextInput
                                    value='Imagen.jpg'
                                    disabled
                                    img={a}
                                />
                            ))}
                        </div>}
                    </div>
                    <div className='claimsData-container-functions'>
                        <div
                            className='claimsData-container-functions-download'
                            onClick={() => getPolizaData(claimData?.productInsuredUser?.id, setPolizaData, null, null)}
                        >
                            {downloading ?
                                <span className="claimsData-container-functions-loader"></span>
                                :
                                <ReactSVG src={DownloadIcon}/>
                            }
                            <p className='claimsData-container-functions-download-text'>&nbsp;{downloading ? 'DESCARGANDO' : 'DESCARGAR LEGAJO'}</p>
                            <span className="claimsData-container-functions-download-tooltiptext">Vas a descargar un archivo .txt con los detalles del reclamo.</span>
                        </div>
                        <p className='claimsData-container-functions-title bold medium'>ESTADO</p>
                        <div className='claimsData-container-functions-statuses'>
                            {estados?.map((a: any) => (
                                <div className='claimsData-container-functions-statuses-item'>
                                    {(a.id == currentEstado?.id) && <div className='claimsData-container-functions-statuses-item-lottie'>
                                        <Lottie
                                            options={{
                                                loop: false,
                                                autoplay: true,
                                                animationData: sentSuccess,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            width={100}
                                            isStopped={false}
                                            isPaused={false}
                                            style={{margin: 0}}
                                        />
                                    </div>}
                                    <div 
                                        className='claimsData-container-functions-statuses-item-data'
                                        style={{ 
                                            opacity: enabled(a.id) ? 1 : 0.4,
                                            height: currentEstado?.id === a.id ? 40 : 30,
                                            backgroundColor: currentEstado?.id === a.id ? a.color : 'var(--white)',
                                            cursor: enabled(a.id) ? 'pointer' : 'default'
                                        }}
                                        onClick={() => {
                                            if(enabled(a.id)) {
                                                setEstadoSelected(a)
                                                setShowEstadoModal(true)
                                            }
                                        }}
                                    >  
                                        <p 
                                            className='claimsData-container-functions-statuses-item-data-label regular small'
                                            style={{ color: !enabled(a.id) ? 'var(--gray200)' : currentEstado?.id !== a.id ? a.color : currentEstado?.name === 'Iniciado' ? 'var(--gray700)' : 'var(--white)' }}
                                        >{a.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <CustomTextInput
                            label='EMAIL DE CONTACTO'
                            value={claimData?.user?.username}
                            disabled
                            copy='Email copiado'
                        />
                    </div>
                </>
            }
            {EstadoAlert()}
        </div>
    );

}

export default ClaimsData;