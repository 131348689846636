import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Claims.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useNavigate } from 'react-router-dom';

const Claims = (props: any) => {

    const navigate = useNavigate();

    return (
        <div className='claims-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='claims-container-main'>
                <div className='claims-container-main-hedear'>
                    <div className='claims-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        userData={props.userData}
                        optionList={props.userList}
                        mini
                    />
                </div>
                <GenericTableAndFilters
                    data={{
                        title: 'Reclamos',
                        search: 'v2/claims',
                        type: 'claims',
                        createPath: '/claims/create',
                        createTitle: 'CREAR RECLAMO',
                    }} canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'claims:create' }).length > 0}
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'claims:download' }).length > 0}
                    goToDetails={(id: any) => navigate('/claims/' + id + '#Detalle%20de%20reclamo')}
                />
            </div>
        </div>
    );
}

export default Claims;