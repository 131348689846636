import React, { useState } from "react";
import "./PopupInfo.css";
import CopyComponent from "../CopyComponent/CopyComponent";
import { useNavigate } from "react-router-dom";

const PopupInfo = (props: any) => {
  const [isTitleOver, setIsTitleOver] = useState(false);
  const [isMailOver, setIsMailOver] = useState(false);
  const [isTelOver, setIsTelOver] = useState(false);
  const [isMouseOverCopyTitle, setIsMouseOverCopyTitle] = useState(false);
  const [isMouseOverCopyMail, setIsMouseOverCopyMail] = useState(false);
  const [isMouseOverCopyTel, setIsMouseOverCopyTel] = useState(false);

  const navigate=useNavigate();

  const handleClickUsuario = (e:any) => {
    e.stopPropagation();
    console.log("quiero ir a"+props.data[3].cta);
    const newURL = props.data[3].cta.substring(props.data[3].cta.lastIndexOf('/',props.data[3].cta.lastIndexOf('/')-1));
    console.log(newURL);
    navigate(newURL);
  };

  const handleTitleOver = () => {
    setIsTitleOver(true);
  };

  const handleMailOver = () => {
    setIsMailOver(true);
  };

  const handleTelOver = () => {
    setIsTelOver(true);
  };

  const handleTitleOut = () => {
    setIsTitleOver(false);
  };

  const handleMailOut = () => {
    setIsMailOver(false);
  };

  const handleTelOut = () => {
    setIsTelOver(false);
  };

  const handleCopyTitle = (e:any) => {
    e.stopPropagation();
    const textToCopy = props.data[0].value;
    navigator.clipboard.writeText(textToCopy)
    .then(() => {
      console.log('Title copied to clipboard');
    })
    .catch((err) => {
      console.error('Failed to copy Title: ', err);
    });
  };

  const handleCopyMail = (e:any) => {
    e.stopPropagation();
    const textToCopy = props.data[1].value;
    navigator.clipboard.writeText(textToCopy)
    .then(() => {
      console.log('Mail copied to clipboard');
    })
    .catch((err) => {
      console.error('Failed to copy mail: ', err);
    });
  };

  const handleCopyTel = (e:any) => {
    e.stopPropagation();
    const textToCopy = props.data[2].value;
    navigator.clipboard.writeText(textToCopy)
    .then(() => {
      console.log('Tel copied to clipboard');
    })
    .catch((err) => {
      console.error('Failed to copy tel: ', err);
    }); 
  };

  const handleMouseEnterCopyTitle=()=>{
    setIsMouseOverCopyTitle(true);
  }

  const handleMouseLeaveCopyTitle=()=>{
    setIsMouseOverCopyTitle(false);
  }

  const handleMouseEnterCopyMail=()=>{
    setIsMouseOverCopyMail(true);
  }

  const handleMouseLeaveCopyMail=()=>{
    setIsMouseOverCopyMail(false);
  }

  const handleMouseEnterCopyTel=()=>{
    setIsMouseOverCopyTel(true);
  }

  const handleMouseLeaveCopyTel=()=>{
    setIsMouseOverCopyTel(false);
  }

  return (
    <div className="popup">
      <div
        className="popupInfo-card"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <div className="simpleContainer" onClick={handleCopyTitle}>
          <p
            className="popupInfo-title"
            onMouseOver={handleTitleOver}
            onMouseOut={handleTitleOut}
          >
            {props.data[0].value}
          </p>
          {(isTitleOver || isMouseOverCopyTitle) && <CopyComponent onMouseEnter={handleMouseEnterCopyTitle}
          onMouseLeave={handleMouseLeaveCopyTitle}/>}
        </div>
        <div className="simpleContainer" onClick={handleCopyMail}>
          <p
            className="popupInfo-text"
            onMouseOver={handleMailOver}
            onMouseOut={handleMailOut}
            onClick={handleCopyMail}
          >
            {props.data[1].value}
          </p>
          {(isMailOver || isMouseOverCopyMail) && <CopyComponent onMouseEnter={handleMouseEnterCopyMail}
          onMouseLeave={handleMouseLeaveCopyMail}/>}
        </div>
        <div className="lowerPart">
          <div className="simpleContainer" onClick={handleCopyTel}>
            <p
              className="popupInfo-text"
              onMouseOver={handleTelOver}
              onMouseOut={handleTelOut}
              onClick={handleCopyTel}
            >
              {props.data[2].value}
            </p>
            {(isTelOver || isMouseOverCopyTel) && <CopyComponent onMouseEnter={handleMouseEnterCopyTel}
          onMouseLeave={handleMouseLeaveCopyTel}/>}
          </div>
          <button className="popupInfo-button" onClick={handleClickUsuario}>
            {props.data[3].value}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupInfo;
