import React, { useEffect, useState } from 'react'
import './DeleteRowModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'
import MainButton from '../../MainButton/MainButton';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/delete.json'
import { actionsDeleteTableRow } from '../../../actions';

const DeleteRowModal = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    return (
        <React.Fragment>
            {success ? 
                <div className='deleteRowModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => {
                            props.onDismiss()
                            window.location.reload();
                        }}
                    />
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: sentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        width={100}
                        isStopped={false}
                        isPaused={false}
                        style={{margin: 0}}
                    />
                    <p className='crearVendedorModal-container-title bold'>¡Excelente!</p>
                    <p className='crearVendedorModal-container-subTitle regular large'>{props.subTitleSuccess}</p>
                    <div className='deleteRowModal-container-buttonWrap'>
                        <MainButton
                            text='ACEPTAR'
                            onPress={() => {
                                props.onDismiss()
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
                :
                <div className='deleteRowModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => props.onDismiss()}
                    />
                    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.54099 30C2.23543 30 1.95766 29.9236 1.70766 29.7708C1.45766 29.6181 1.26321 29.4167 1.12432 29.1667C0.985435 28.9167 0.909046 28.6458 0.895157 28.3542C0.881268 28.0625 0.957657 27.7778 1.12432 27.5L16.541 0.833333C16.7077 0.555556 16.9229 0.347222 17.1868 0.208333C17.4507 0.0694444 17.7215 0 17.9993 0C18.2771 0 18.5479 0.0694444 18.8118 0.208333C19.0757 0.347222 19.291 0.555556 19.4577 0.833333L34.8743 27.5C35.041 27.7778 35.1174 28.0625 35.1035 28.3542C35.0896 28.6458 35.0132 28.9167 34.8743 29.1667C34.7354 29.4167 34.541 29.6181 34.291 29.7708C34.041 29.9236 33.7632 30 33.4577 30H2.54099ZM5.41599 26.6667H30.5827L17.9993 5L5.41599 26.6667ZM17.9993 25C18.4715 25 18.8674 24.8403 19.1868 24.5208C19.5063 24.2014 19.666 23.8056 19.666 23.3333C19.666 22.8611 19.5063 22.4653 19.1868 22.1458C18.8674 21.8264 18.4715 21.6667 17.9993 21.6667C17.5271 21.6667 17.1313 21.8264 16.8118 22.1458C16.4924 22.4653 16.3327 22.8611 16.3327 23.3333C16.3327 23.8056 16.4924 24.2014 16.8118 24.5208C17.1313 24.8403 17.5271 25 17.9993 25ZM17.9993 20C18.4715 20 18.8674 19.8403 19.1868 19.5208C19.5063 19.2014 19.666 18.8056 19.666 18.3333V13.3333C19.666 12.8611 19.5063 12.4653 19.1868 12.1458C18.8674 11.8264 18.4715 11.6667 17.9993 11.6667C17.5271 11.6667 17.1313 11.8264 16.8118 12.1458C16.4924 12.4653 16.3327 12.8611 16.3327 13.3333V18.3333C16.3327 18.8056 16.4924 19.2014 16.8118 19.5208C17.1313 19.8403 17.5271 20 17.9993 20Z" fill="var(--primary500)"/>
                    </svg>
                    <p className='deleteRowModal-container-title bold' dangerouslySetInnerHTML={{ __html: props.title }} />
                    <p className='deleteRowModal-container-subTitle regular large' dangerouslySetInnerHTML={{ __html: props.data }} />
                    <div className='deleteRowModal-container-buttonList'>
                        <MainButton
                            text='CANCELAR'
                            sinFondo
                            onPress={() => props.onDismiss()}
                        />
                        <MainButton
                            text={loading ? 'ELIMINANDO...' : 'ACEPTAR'}
                            onPress={() => actionsDeleteTableRow(props.deleteId, '/v2/user', setLoading, setSuccess)}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );

}

export default DeleteRowModal;