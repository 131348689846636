import React, { useEffect, useState } from 'react'
import LeftArrow from '../../assets/images/svgs/leftArrow.svg';
import './OrdenarPor.css'
import { ReactSVG } from 'react-svg'

const OrdenarPor = (props: any) => {

    const [sortOpen, setSortOpen] = useState<boolean>(false)
    const [currentSort, setCurrentSort] = useState<string>('')

    useEffect(() => {
        setCurrentSort(props.default)
    }, [props.default])


    return (
        <div className='ordenarPor-container'>
            <div className='ordenarPor-container-labels'>
                <p className='ordenarPor-container-labels-text' style={{color: 'var(--gray700)'}}>Ordenar por</p>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={() => setSortOpen(!sortOpen)}>
                    <p className='ordenarPor-container-labels-text' style={{color: 'var(--primary500)'}}>&nbsp;&nbsp;{currentSort}&nbsp;&nbsp;&nbsp;</p>
                    <ReactSVG
                        src={LeftArrow}
                        style={{ 
                            transform: !sortOpen ? 'rotate(270deg)' : 'rotate(90deg)', 
                            transition: 'transform 150ms ease' 
                        }}
                    />
                </div>
            </div>
            {sortOpen && 
                <div className='ordenarPor-container-list'>
                    {props.sortList?.map((a: any, index: number) => (
                        <p 
                            className='ordenarPor-container-list-text'
                            key={index+'sort'}
                            style={{
                                borderTopLeftRadius: index === 0 ? 15 : 0,
                                borderTopRightRadius: index === 0 ? 15 : 0,
                                borderBottomRightRadius: index === props.sortList.length -1 ? 15 : 0,
                                borderBottomLeftRadius: index === props.sortList.length -1 ? 15 : 0
                            }}
                            onClick={() => {
                                setSortOpen(false)
                                setCurrentSort(a.name)
                                props.itemSelected(a)
                            }}
                        >{a.name}</p>
                    ))}
                </div>
            }
        </div>
    );

}

export default OrdenarPor;