import axios from './instanceActions'
import { 
    netUserClientData, 
    getCivilTypes,
    getSexTypes,
    getStatesCon,
    getLocByIdCon,
    netUserPolizasData,
    netUserCotizacionesData,
    netUserClaimsData,
    netUserDataFromClaim,
    netUserDataFromPoliza,
    getPardoData
} from "../net/Connector";

export const getUserClaimsData = (userId: any, setUserClaimsData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserClaimsData(userId))
        .then((response) => {
            setUserClaimsData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserCotizacionesData = (userId: any, setUserCotizaiconesData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserCotizacionesData(userId))
        .then((response) => {
            setUserCotizaiconesData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserPolizasData = (userId: any, setUserPolizasData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserPolizasData(userId))
        .then((response) => {
            setUserPolizasData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserClientDataWeco = (userId: any, setUserClientData: any, setGeneroList: any, setCivilList: any, setLocalidad: any, setProvinciaList: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = '';
    if(from === 'claim') {
        url = netUserDataFromClaim(userId);
    } else if(from === 'poliza') {
        url = netUserDataFromPoliza(userId);
    } else {
        url = netUserClientData(userId);
    }
    axios.get(url)
        .then((response) => {
            let userData = response.data
            setUserClientData(userData)
            axios.get(getCivilTypes())
                .then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => {
                        let item: any = {codigo: a.codigo, label: a.descripcion}
                        objCivilType.push(item)
                    })
                    setCivilList(objCivilType)
                    axios.get(getSexTypes())
                        .then(responseS => {
                            let arraySexTypes: any = [];
                            responseS.data.map((a: any) => {
                                let item: any = { codigo: a.codigo, label: a.descripcion}
                                arraySexTypes.push(item)
                            })
                            setGeneroList(arraySexTypes)
                            axios.get(getStatesCon()).then(responseStates => {
                                setProvinciaList(responseStates.data)
                                axios.get(getLocByIdCon(userData.localidadId)).then(responseLoc => {
                                    setLocalidad(responseLoc.data[0]?.localidad)
                                    setLoading(false)
                                }).catch(error => {
                                    setLoading(false)
                                });
                            }).catch(error => {
                                setLoading(false)
                            });
                        })
                        .catch(error => {
                            setLoading(false)
                        })
                })
                .catch(error => {
                    setLoading(false)
                });
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserClientDataPardo = (userId: any, setUserClientData: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = '';
    if(from === 'claim') {
        url = netUserDataFromClaim(userId);
    } else if(from === 'poliza') {
        url = netUserDataFromPoliza(userId);
    } else {
        url = netUserClientData(userId);
    }
    axios.get(url)
        .then((response) => {
            let userData = response.data
            setUserClientData(userData)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

//LISTADOS

export const getListsArgPardo = (setTipoDocuList: any, setSexoList: any, setEstadoCivilList: any, setNacionalidadList: any, setTipoDomiList: any) => {
    axios.get(getPardoData("tipo_documentos")).then(responseTipoDocumentos => {
        setTipoDocuList(responseTipoDocumentos.data);
        axios.get(getPardoData("sexos")).then(responseSexos => {
            setSexoList(responseSexos.data);
            axios.get(getPardoData("estado_civil")).then(responseEstadoCivil => {
                setEstadoCivilList(responseEstadoCivil.data);
                axios.get(getPardoData("paises")).then(responsePaises => {
                    setNacionalidadList(responsePaises.data);
                    axios.get(getPardoData("tipo_domicilio")).then(responseTipoDomicilio => {
                        setTipoDomiList(responseTipoDomicilio.data);
                    }).catch(error => {
                    });
                }).catch(error => {
                });
            }).catch(error => {
            });
        }).catch(error => {
        });
    }).catch(error => {
    });
}