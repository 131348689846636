import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

const ProtectedRoute = (props: any) => {
    const location = useLocation();
    
    const checkPrivileges = () => {
        const path = '/' + location.pathname.substring(1).split('/')[0]
        const section = props.principalList?.filter((a: any) => a.path === path)[0]
        const privileges = props.userData?.privileges?.filter(function(e: any) { return e === section?.privilege })
        if(props.principalList && props.userData) {
            if(privileges?.length === 0 && props.userData?.role?.name === "ROLE_MOBILE") {
                const cookies = new Cookies();
                cookies.remove('userAccessToken');
                cookies.remove('rememberAccount');
            }
            return privileges?.length > 0
        } return true
    }

    return (checkPrivileges() ? <Outlet/> : <Navigate to='/'/>)
};
  
export default ProtectedRoute;