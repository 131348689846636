import React, { useEffect, useState } from 'react'
import { 
    getPolizaData
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasData.css'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';

const PolizasData = (props: any) => {

    const [polizaData, setPolizaData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
    }, [])

    return (
        <div className='polizasData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasData-container-data'>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='NRO DE COBERTURA'
                            value={polizaData?.number}
                            disabled
                            color={polizaData?.status?.type === 'ACTIVE' ? 'var(--successPrimary)' : polizaData?.status?.type === 'INACTIVE' ? 'var(--errorPrimary)' : 'var(--warningPrimary)'}
                        />
                        <CustomTextInput
                            label='ESTADO'
                            value={polizaData?.status?.type}
                            disabled
                            color={polizaData?.status?.type === 'ACTIVE' ? 'var(--successPrimary)' : polizaData?.status?.type === 'INACTIVE' ? 'var(--errorPrimary)' : 'var(--warningPrimary)'}
                        />
                        <CustomTextInput
                            label='FECHA DE ALTA'
                            value={Moment(polizaData?.fechaDesde).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={polizaData?.productPolicyDTO?.categoryName}
                            disabled
                        />
                        <CustomTextInput
                            label='ESPECIFICACIONES'
                            value={polizaData?.productPolicyDTO?.name + ' ' + polizaData?.productPolicyDTO?.model}
                            disabled
                        />
                        <CustomTextInput
                            label='ASEGURA'
                            value={polizaData?.productPolicyDTO?.insurerName}
                            disabled
                        />
                        <CustomTextInput
                            label='PAGA'
                            value={polizaData?.coveragePolicyDTO?.valueToPay}
                            disabled
                            underText={'PRÓX CUOTA: ' + polizaData?.coveragePolicyDTO?.valueToPay}
                            underColor='var(--warningPrimary)'
                        />
                        <CustomTextInput
                            label='POR'
                            value={polizaData?.coveragePolicyDTO?.renewAutomatic}
                            disabled
                        />
                        <CustomTextInput
                            label='VIGENCIA'
                            value={Moment(polizaData?.fechaHasta).format('DD/MM/YYYY')}
                            disabled
                        />
                    </div>
                    <h5 className='polizasData-container-data-title bold'>Más datos</h5>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='SUMA ASEGURADA'
                            value={polizaData?.coveragePolicyDTO?.valor}
                            disabled
                        />
                        <CustomTextInput
                            label='CUBRIMOS HASTA'
                            value={polizaData?.productPolicyDTO?.productValue}
                            disabled
                        />
                        <CustomTextInput
                            label='TIPO DE COBERTURA'
                            value='Robo y daños'
                            disabled
                        />
                        <CustomTextInput
                            label='PORCENTAJE'
                            value={polizaData?.coveragePolicyDTO?.porcentaje}
                            disabled
                        />
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasData;