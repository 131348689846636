import React, { useState } from 'react'
import './UserAdminProfile.css'
import LeftArrow from '../../assets/images/svgs/leftArrow.svg';
import { ReactSVG } from 'react-svg';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import GenericModal from '../GenericModal/GenericModal';
import MenuItem from '../MenuItem/MenuItem';
import emptyProfile from '../../assets/images/EmptyProfile.png';
import { useIntl } from 'react-intl';

const UserAdminProfile = (props) => {

    const [openList, setOpenList] = useState(false);
    const [logoutAlert, setLogoutAlert] = useState(false);

    const navigate = useNavigate();

    const LogoutModal = () => {
        return (
            <Modal
                isOpen={logoutAlert}
                onRequestClose={() => setLogoutAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <GenericModal
                    icon='logout'
                    title={useIntl().formatMessage({id:"admin.user.modal.cerrar"})}
                    buttonText='CERRAR SESIÓN'
                    onPress={() => {
                        const cookies = new Cookies();
                        cookies.remove('userAccessToken');
                        cookies.remove('rememberAccount');
                        navigate('/');
                    }}
                    onDismiss={() => setLogoutAlert(false)}
                />
            </Modal>
        )
    }

    return (
        <div className='userAdminProfile-container'>
            <div className='userAdminProfile-container-userContainer'>
                <div className='userAdminProfile-container-userContainer-userData'>
                    <div className='userAdminProfile-container-userContainer-userData-imgWrap'>
                        <img 
                            className='userAdminProfile-container-userContainer-userData-imgWrap-img' 
                            src={props.userData?.imageProfile ? props.userData?.imageProfile : emptyProfile}
                        />
                    </div>
                    <div className='userAdminProfile-container-userContainer-userData-dataWrap'>
                        <p className='userAdminProfile-container-userContainer-userData-dataWrap-name bold'>{props.userData?.name + ' ' + props.userData?.lastName}</p>
                        <p 
                            className='userAdminProfile-container-userContainer-userData-dataWrap-rol bold small' 
                            style={{backgroundColor: props.userData?.role?.color}}>
                            {props.userData?.role?.name}
                        </p>
                    </div>
                </div>
                <ReactSVG
                    src={LeftArrow}
                    onClick={() => setOpenList(!openList)}
                    style={{ 
                        cursor: 'pointer', 
                        transform: !openList ? 'rotate(270deg)' : 'rotate(90deg)', 
                        transition: 'transform 150ms ease' 
                    }}
                />
            </div>
            {openList && 
                <div className='userAdminProfile-container-listContainer'>
                    <MenuItem
                        data={{ icon: "logout", name: 'Cerrar sesión', enable: true}}
                        onPresss={() => setLogoutAlert(true)}
                        enable
                    />
                </div>
            }
            {LogoutModal()}
        </div>
    );

}

export default UserAdminProfile;