import React, { useState } from 'react'
import './BurgerMenuItem.css'

const BurgerMenuItem = (props) => {

    return (
        <div className='burgerMenuItem-container'>
            <p
                className={props.selected ? 'burgerMenuItem-container-iconTextSelected' : !props.data.enable ? 'burgerMenuItem-container-iconTextDis' : 'burgerMenuItem-container-iconText'}
                onClick={() => props.data.enable && !props.selected ? props.onPresss() : {}}
                style={{
                    justifyContent: props.show ? 'unset' : 'center', 
                    paddingLeft: props.show ? 10 : 0,
                    opacity: props.data.enable ? 1 : 0.4
                }}
            >
                <span className="material-symbols-rounded">{props.data.icon}</span>
                {props.show && <span className='burgerMenuItem-container-item-text regular large'>{props.data.name}</span>}
            </p>
            <div className='burgerMenuItem-container-selectedIcon' style={{backgroundColor: props.selected ? 'var(--primary500)' : 'transparent'}}></div>
            {props.data.comingSoon && props.show && <p className='burgerMenuItem-container-muyPronto'>MUY PRONTO</p>}
        </div>
    );

}

export default BurgerMenuItem;