import React from 'react'
import MainButton from '../../MainButton/MainButton';
import './EstadoModal.css'

const EstadoModal = (props: any) => {

    return (
        <div className='estadoModal-container'>
            <div 
                className='estadoModal-container-close'
                onClick={() => props.cancelar()}
            >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00011 8.40005L2.10011 13.3C1.91678 13.4834 1.68344 13.575 1.40011 13.575C1.11678 13.575 0.883443 13.4834 0.700109 13.3C0.516776 13.1167 0.42511 12.8834 0.42511 12.6C0.42511 12.3167 0.516776 12.0834 0.700109 11.9L5.60011 7.00005L0.700109 2.10005C0.516776 1.91672 0.42511 1.68338 0.42511 1.40005C0.42511 1.11672 0.516776 0.883382 0.700109 0.700048C0.883443 0.516715 1.11678 0.425049 1.40011 0.425049C1.68344 0.425049 1.91678 0.516715 2.10011 0.700048L7.00011 5.60005L11.9001 0.700048C12.0834 0.516715 12.3168 0.425049 12.6001 0.425049C12.8834 0.425049 13.1168 0.516715 13.3001 0.700048C13.4834 0.883382 13.5751 1.11672 13.5751 1.40005C13.5751 1.68338 13.4834 1.91672 13.3001 2.10005L8.40011 7.00005L13.3001 11.9C13.4834 12.0834 13.5751 12.3167 13.5751 12.6C13.5751 12.8834 13.4834 13.1167 13.3001 13.3C13.1168 13.4834 12.8834 13.575 12.6001 13.575C12.3168 13.575 12.0834 13.4834 11.9001 13.3L7.00011 8.40005Z" fill="#333333"/>
                </svg>
            </div>
            <p className='estadoModal-container-title bold'>¿Confirmas el cambio de estado<br/>{props.currentEstado?.label} a {props.estadoSelected?.label} para {props.claimData?.name}?</p>
            <p className='estadoModal-container-text bold small'>ESCRIBE UN COMENTARIO PARA TU CLIENTE <p className='light small'> (Opcional)</p></p>
            <p className='estadoModal-container-text regular small'>Este mensaje será enviado a su e-mail informando el cambio de estado.</p>
            <textarea
                className='estadoModal-container-textarea'
                value={props.statusText}
                onChange={(e) => props.setStatusText(e.target.value)}
            />
            <div className='estadoModal-container-buttons'>
                <div className='estadoModal-container-buttons-b1'>
                    <MainButton
                        id='CANCELAR'
                        text='CANCELAR'
                        onPress={() => props.cancelar()}
                        sinFondo
                    />
                </div>
                <div className='estadoModal-container-buttons-b1'>
                    <MainButton
                        id='CONFIRMAR'
                        text='CONFIRMAR'
                        onPress={() => props.confirmar()}
                    />
                </div>
            </div>
        </div>
    );

}

export default EstadoModal;