import React, { useState } from 'react'
import './CrearVendedorModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'
import MainButton from '../../MainButton/MainButton';
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/success.json'
import { actionsCreateItem } from '../../../actions';

const CrearVendedorModal = (props: any) => {

    const [nombre, setNombre] = useState<string>('')
    const [apellido, setApellido] = useState<string>('')
    const [dni, setDni] = useState<string>('')
    const [email, setEmail] = useState<string>('')

    const [tipo, setTipo] = useState<string>('Sucursal');

    const [contra, setContra] = useState<string>('')
    const [reContra, setReContra] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const validatePassword = (e: string) => {
        if (e.length >= 8) {
            if (/\d/.test(e) && /[a-zA-Z]/.test(e)) {
                if (/[A-Z].*\d|\d.*[A-Z]/.test(e)) {
                    return true
                }
            }
        }
        return false
    }

    const validatePasswordRedo = (recontra: any, contra: any) => {
        if (recontra.length > 0) {
            if (recontra === contra) {
                return validatePassword(contra)
            }
        } return false
    }

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    };

    const isDisabled = () => {
        return (!validateEmail(email) || !isValidName(nombre) || !isValidName(apellido) || dni === '' || !validatePasswordRedo(reContra, contra))
    }

    return (
        <React.Fragment>
            {success ? 
                <div className='crearVendedorModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => {
                            props.onDismiss()
                            window.location.reload();
                        }}
                    />
                     <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: sentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        width={223}
                        isStopped={false}
                        isPaused={false}
                        style={{margin: 0}}
                    />
                    <p className='crearVendedorModal-container-title bold'>¡Excelente!</p>
                    <p className='crearVendedorModal-container-subTitle regular large'>El vendedor ha sido creado exitosamente.<br/>Podrás visualizarlo en el listado de vendedores.</p>
                    <div className='crearVendedorModal-container-buttonWrap'>
                        <MainButton
                            text='ACEPTAR'
                            onPress={() => {
                                props.onDismiss()
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
                :
                <div className='crearVendedorModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => props.onDismiss()}
                    />
                    <p className='crearVendedorModal-container-title bold'>Crear vendedor</p>
                    <p className='crearVendedorModal-container-subTitle regular large'>Ingresá los siguientes datos para dar de alta un nuevo vendedor.</p>
                    <div className='crearVendedorModal-container-inputList'>
                        <CustomTextInput
                            label='APELLIDO'
                            onChange={(e: any) => {
                                setApellido(e.target.value)
                                setError('')
                            }}
                            value={apellido}
                            error={apellido.length > 0 && !isValidName(apellido)}
                            success={apellido.length > 0 && isValidName(apellido)}
                            required
                            errorMessage={error}
                        />
                        <CustomTextInput
                            label='NOMBRE'
                            onChange={(e: any) => {
                                setNombre(e.target.value)
                                setError('')
                            }}
                            value={nombre}
                            error={nombre.length > 0 && !isValidName(nombre)}
                            success={nombre.length > 0 && isValidName(nombre)}
                            required
                            errorMessage={error}
                        />
                        <CustomTextInput
                            label='DNI'
                            placeholder='Sólo números'
                            onChange={(e: any) => setDni(e.target.value.replace(/[^0-9]+/g, ''))}
                            success={dni !== ''}
                            value={dni}
                            required
                        />
                        <CustomTextInput
                            label='E-MAIL'
                            placeholder='nombre@ejemplo.com'
                            onChange={(e: any) => setEmail(e.target.value)}
                            value={email}
                            error={(email.length > 0 && !validateEmail(email)) || error}
                            errorMessage={error}
                            success={validateEmail(email)}
                            required
                        />
                    </div>
                    <div className='crearVendedorModal-container-type'>
                        <b className='crearVendedorModal-container-type-title medium bold'>TIPO DE VENDEDOR</b>
                        <div className='crearVendedorModal-container-type-list'>
                            <div 
                                className='crearVendedorModal-container-type-list-item'
                                onClick={() => setTipo('Sucursal')}
                            >
                                <div className='crearVendedorModal-container-type-list-item-circle'>
                                    <div 
                                        className='crearVendedorModal-container-type-list-item-circle-inner'
                                        style={{ transform: tipo === 'Sucursal' ? `scale(${1})` : `scale(${0})` }}
                                    />
                                </div>
                                <b className='crearVendedorModal-container-type-list-item-text medium bold'>SUCURSAL</b>
                            </div>
                            <div 
                                className='crearVendedorModal-container-type-list-item'
                                onClick={() => setTipo('Callcenter')}
                            >
                                <div className='crearVendedorModal-container-type-list-item-circle'>
                                    <div 
                                        className='crearVendedorModal-container-type-list-item-circle-inner'
                                        style={{ transform: tipo === 'Callcenter' ? `scale(${1})` : `scale(${0})` }}
                                    />
                                </div>
                                <b className='crearVendedorModal-container-type-list-item-text medium bold'>CALLCENTER</b>
                            </div>
                        </div>
                    </div>
                    <div className='crearVendedorModal-container-inputList'>
                        <CustomTextInput
                            label='CREAR CONTRASEÑA'
                            placeholder='**********'
                            onChange={(e: any) => setContra(e.target.value)}
                            value={contra}
                            underText='Debe contener más de 8 caracteres, 1 mayúscula y 1 número'
                            successAlt={validatePassword(contra)}
                            ispassword
                            required
                        />
                        <CustomTextInput
                            label='REPETIR CONTRASEÑA'
                            placeholder='**********'
                            onChange={(e: any) => setReContra(e.target.value)}
                            value={reContra}
                            success={validatePasswordRedo(reContra, contra)}
                            error={reContra.length > 0 ? !validatePasswordRedo(reContra, contra) : false}
                            errorMessage='Las contraseñas no coinciden'
                            ispassword
                            required
                        />
                    </div>
                    <div className='crearVendedorModal-container-buttonList'>
                        <MainButton
                            text='CANCELAR'
                            sinFondo
                            onPress={() => props.onDismiss()}
                        />
                        <MainButton
                            text={loading ? 'CREANDO...' : 'FINALIZAR'}
                            disabled={isDisabled()}
                            onPress={() => {
                                const data = {
                                    "name": nombre,
                                    "lastName": apellido,
                                    "userName": email,
                                    "addressFloor": '_',
                                    "addressFormatGM": '_',
                                    "addressPostalCode": '_',
                                    "addressStreet": '_',
                                    "birthDate": '2023-07-07T13:58:16.958Z',
                                    "civilStatusId": 0,
                                    "colonia": '_',
                                    "cuil": '_',
                                    "delegacion": '_',
                                    "documentId": '1',
                                    "documentNumber": dni,
                                    "localidad": 0,
                                    "motherLastName": '_',
                                    "nacionalityId": '2',
                                    "phone": '_',
                                    "provincia": 0,
                                    "sexId": 'male',
                                    "password": contra,
                                    "roles": [5],
                                    "tipoVendedor": tipo
                                }
                                actionsCreateItem(data, '/v2/user/fast_user', setLoading, setSuccess, setError)
                            }}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );

}

export default CrearVendedorModal;