import React, { useEffect, useState } from 'react'
import './BurgerMenu.css'
import BurgerMenuItem from './BurgerMenuItem/BurgerMenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { sonLogo } from '../../data';

const BurgerMenu = (props: any) => {

    const [hovered, setHovered] = useState(false)
    const [selectedIcon, setSelectedIconId] = useState(-1)

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = '/' + location.pathname.substring(1).split('/')[0]
        setSelectedIconId(props.principalList?.filter((a: any) => a.path === path)[0].id)
    }, [location.pathname, props.principalList])

    return (
        <div
            className='burgerMenu-container'
            onMouseEnter={() => setHovered(true)}
            onMouseOver={() => setHovered(true)}
            onMouseUp={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className='burgerMenu-container-logo'>
                <img className='burgerMenu-container-logo-img' src={sonLogo} alt='logo'/>
            </div>
            <div className='burgerMenu-container-line'></div>
            <div className='burgerMenu-container-itemList'>
                {props.principalList?.map((a: any, index: any) => (
                    <BurgerMenuItem
                        key={index}
                        data={a}
                        selected={selectedIcon === a.id}
                        onPresss={() => navigate(a.path)}
                        show={hovered}
                    />
                ))}
            </div>
        </div>
    );

}

export default BurgerMenu;