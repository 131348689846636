import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';
import { ReactComponent as Mexico } from './assets/images/svgs/mexicoLogo.svg';

export const data = null;

export let sonLogo = 'https://resources.wecover.co/wecoverlogo.png';

export let sonLogoSize  = '10%';

export const changeLogo = (url: string) => {
    sonLogo = url;
}

export const countries = [
    {
        id: 1,
        title: "ARGENTINA",
        img: Argentina,
        url: '/ar',
        lang: () => import('./assets/lang/es-AR.json'),
    },
    {
        id: 2,
        title: "MÉXICO",
        img: Mexico,
        url: '/mx',
        lang: () => import('./assets/lang/es-MX.json'),
    }
];