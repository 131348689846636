import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import OrdenarPor from "../OrdenarPor/OrdenarPor";
import "./TableFilters.css";
import DownloadIcon from "../../assets/images/svgs/downloadIcon.svg";
import Moment from "moment/moment";
import OpcionComponent from "./OpcionComponent/OpcionComponent";
import MainButton from "../MainButton/MainButton";
import ChipComponent from "./ChipComponent";
import txtImage from '../../assets/images/svgs/txt.svg'

const TableFilters = (props: any) => {
  const [textFilter, setTextFilter] = useState<any>(null);
  const [localTextLoader, setLocalTextLoader] = useState<boolean>(false);
  const [timeout, timeoutChange] = useState<any>(0);
  const [sortTable, setSortTable] = useState<any>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [includedMenu, setIncludedMenu] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>([]);
  const [finalFilter, setFinalFilter] = useState<any>([]);
  const [activeFilterNames, setActiveFilterNames] = useState<any>([]);
  const [activeObjects, setActiveObjects] = useState<any>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    //if coberturas activas button was clicked in the dashboard
    console.log("primer renderizado");
    if (sessionStorage.getItem("Activa")) {
      const timer = setTimeout(() => {
        console.log("entre");
        setActiveFilterNames(["Activa"]);
        setActiveObjects(() => {
          return [
            {
              "checkout.status": { $eq: "FINISHED" },
              "product_insured_user.enabled": { $eq: true },
            },
          ];
        });
        setFilter(() => {
          return [
            {
              $or: [
                {
                  "checkout.status": { $eq: "FINISHED" },
                  "product_insured_user.enabled": { $eq: true },
                },
              ],
              title: "ESTADO",
            },
          ];
        });
        setFilterApplied(true);
        console.log('aplicando')
      }, 1000);
      //limpieza del useEffect
      return () => clearTimeout(timer)
    }
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    //update the disabled state of APLICAR
    if (activeFilterNames.length !== 0) {
      console.log("cambio la lista de nombres de filtros activos");
      console.log(activeFilterNames);
      setIsChanged(true);
    }
  }, [activeFilterNames]);

  useEffect(() => {
    console.log("cambio la lista de objetos de filtros activos");
    console.log(activeObjects);
  }, [activeObjects]);

  useEffect(() => {
    if (includedMenu === false) {
      console.log("cambio el filtro");
      console.log(filter);
      setFinalFilter(reformFilterArray());
    }
  }, [filter]);

  useEffect(() => {
    /* console.log("Es primer render ", isFirstRender); */
    //i call server only if it is not the first render
    /* if (!isFirstRender) { */
      console.log("cambio final filter, hago peticion");
      const data = getDataForPage(1, null, textFilter, finalFilter);
      props.sortChange(data);
      setIsChanged(false);
    }
 , [finalFilter]);

  //to check if two objects are equal
  function areObjectsEqual(obj1:any, obj2:any) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (typeof val1 === "object" && typeof val2 === "object") {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }
    return true;
  }

  function findAndReplace(
    object: any,
    value: any,
    replacevalue: any,
    once = true
  ) {
    for (var x in object) {
      if (object.hasOwnProperty(x)) {
        if (typeof object[x] == "object") {
          findAndReplace(object[x], value, replacevalue, once);
        }
        let val = object[x];
        if (val === value) {
          object[x] = replacevalue;
          if (once === true) break;
        }
      }
    }
  }

  function findAndReplaceInmutable(
    object: any,
    value: any,
    replacevalue: any,
    once = true
  ) {
    let copiedObject = JSON.parse(JSON.stringify(object));
    findAndReplace(copiedObject, value, replacevalue, once);
    return copiedObject;
  }

  const getDataForPage = (
    pageNumber: number,
    sortForm: any,
    /* fechaDesdeForm: any,
    fechaHastaForm: any, */
    textForm: any,
    filterForm: any
  ) => {
    let sort: any = null;
    let page: any = null;
    let filter: any = [];
    let data: any = {};
    console.log(filterForm);
    if (sortForm) {
      sort = sortForm;
      data["sort"] = sort;
    }
    if (filterForm) {
      filter = filterForm;
      data["filter"] = filter;
    }
    if (pageNumber) {
      page = {
        number: pageNumber,
        size: 40,
      };
      data["page"] = page;
    }
    if (textForm) {
      const newFilter = findAndReplaceInmutable(
        props.formData?.excluded?.filters[0]?.elements[0]?.filter,
        "$VALOR",
        textForm
      );
      filter.push(newFilter);
      data["filter"] = filter;
    }
    props.setDataConfig(data);
    console.log(data);
    return data;
  };

  const handleSearchTextChange = (text: string) => {
    setTextFilter(text);
    setLocalTextLoader(true);
    if (timeout) timeoutChange(clearTimeout(timeout));
    timeoutChange(
      setTimeout(() => {
        const data = getDataForPage(1, sortTable, text, null);
        props.sortChange(data);
        setLocalTextLoader(false);
      }, 2000)
    );
  };

  const handleSorting = (sort: any) => {
    setSortTable(sort?.config);
    console.log(finalFilter);
    const data = getDataForPage(1, sort?.config, textFilter, finalFilter);
    props.sortChange(data);
  };

  const handleClose = () => {
    setIncludedMenu(false);
    //if there is a close click
    //check the concistency of ActiveFilterNames array, it must have the same keys as sessionStorage
    setActiveFilterNames(() => {
      const updatedNames = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const keyName = sessionStorage.key(i);
        updatedNames.push(keyName);
      }
      console.log(updatedNames);
      return updatedNames;
    });
  };

  const handleChipClose = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: any
  ) => {
    const chipElement = event.currentTarget;
    chipElement.remove();
    const toDelete = activeObjects[index];
    console.log("buscando para eliminar:");
    console.log(toDelete);

    setActiveFilterNames(
      activeFilterNames.filter((filterName: any, pos: any) => pos !== index)
    );

    setActiveObjects(
      activeObjects.filter((filterObject: any, pos: number) => pos !== index)
    );

    setFilter((prevFilter: any) => {
      let updatedFilter = prevFilter.map((eachFilter: any) => {
        if (eachFilter.hasOwnProperty("$or")) {
          const objectIndex = eachFilter?.$or?.findIndex((obj: any) =>
            areObjectsEqual(obj, toDelete)
          );
          console.log("Indice a eliminar " + objectIndex);
          if (objectIndex === -1) {
            return eachFilter;
          } else {
            const orUpdated = eachFilter.$or.filter(
              (val: any, index: number) => index !== objectIndex
            );
            const filterUpdated = {
              ...eachFilter,
              $or: orUpdated,
            };
            return filterUpdated;
          }
        } else {
          console.log("lo que tengo", eachFilter);
          console.log("lo que quiero borrar", toDelete);
          const dates = eachFilter["product_insured_user.date_insured"];
          if (dates !== toDelete["product_insured_user.date_insured"]) {
            return eachFilter;
          } else {
            return null;
          }
        }
      });
      updatedFilter = updatedFilter.filter((fil: any) => fil !== null);
      return updatedFilter;
    }); //fin setFilter
  }; //fin chipCLose

  const addToFilter = (filterTitle: any, value: any, filterOption: string) => {
    console.log(value);
    //filter name array (only strings with names)
    setActiveFilterNames((prevNames: any) => {
      const updatedNames = [...prevNames];
      const existingNameIndex = updatedNames.findIndex(
        (uniqueName) => uniqueName === filterOption
      );
      //if doesn't exist add it, else splice it
      existingNameIndex === -1 ? updatedNames.push(filterOption) : evaluate();

      function evaluate() {
        console.log(filterOption);
        if (filterOption !== "Desde" && filterOption !== "Hasta") {
          updatedNames.splice(existingNameIndex, 1);
        }
      }
      return updatedNames;
    });
    //filter objects array to find what to delete when a chip is closed
    setActiveObjects((prevObjects: any) => {
      const updatedObjects = [...prevObjects];
      //check if exists
      const existingObjectIndex = updatedObjects.findIndex((obj) =>
        areObjectsEqual(obj, value)
      );
      //if doesn't exist add it, else splice it
      existingObjectIndex === -1 ? updatedObjects.push(value) : evaluate();

      function evaluate() {
        if (filterOption !== "Desde" && filterOption !== "Hasta") {
          updatedObjects.splice(existingObjectIndex, 1);
        }
      }
      console.table(updatedObjects);
      return updatedObjects;
    });

    setFilter((prevFilter: any) => {
      // Check if the 'filterTitle' exists in the 'filter' array
      console.log(prevFilter);
      //check if FECHA
      if (filterTitle === "FECHA") {
        const existingFilterIndex = prevFilter.findIndex(
          (filter: any) => filter.title === filterOption
        );
        const updatedObject = value;
        console.log(updatedObject);
        if (existingFilterIndex === -1) {
          console.log("no existe fecha " + filterOption);
          const updatedFilter = [
            ...prevFilter,
            { title: filterOption, ...updatedObject },
          ];
          console.log(updatedFilter);
          return updatedFilter;
        } else {
          console.log(
            "Existe " + filterOption + ", lo reemplazo con el nuevo valor"
          );
          const updatedFilter = prevFilter
            .map((filter: any, index: number) => {
              if (index === existingFilterIndex) {
                return { title: filterOption, ...updatedObject };
              }
              return filter;
            })
            .filter((fil: any) => fil !== null);
          console.log(updatedFilter);
          return updatedFilter;
        }
      }

      const existingFilterIndex = prevFilter.findIndex(
        (filter: any) => filter.$or && filter.title === filterTitle
      );
      /* console.log('existe? posicion'+existingFilterIndex); */
      if (existingFilterIndex === -1) {
        // If the 'filterTitle' doesn't exist, create a new filter object with an '$or' array
        const updatedFilter = [
          ...prevFilter,
          { title: filterTitle, $or: [value] },
        ];
        console.log("es un filtro nuevo");
        console.log(updatedFilter);
        return updatedFilter;
      }
      //if the filter title already exists
      const existingFilter = prevFilter[existingFilterIndex];
      //does the value already exists ?  yes:i took it out, no:i add it to the array
      const updatedOrArray = existingFilter.$or.includes(value)
        ? existingFilter.$or.filter((val: any) => val !== value)
        : [...existingFilter.$or, value];

      const updatedFilter = [...prevFilter];
      updatedFilter[existingFilterIndex] = {
        ...existingFilter,
        $or: updatedOrArray,
      };
      return updatedFilter;
    });
  };

  const reformFilterArray = () => {
    sessionStorage.clear();
    const names = [...activeFilterNames];
    names.forEach((activeFilter: any) => {
      if (activeFilter === "Desde") {
        const desde = filter.find((fil: any) => fil.title === "Desde");
        sessionStorage.setItem(
          activeFilter,
          JSON.stringify(
            Moment(desde["product_insured_user.date_insured"]["$gte"])
              .add(1, "day")
              .format("DD/MM/YYYY")
          )
        );
      } else if (activeFilter === "Hasta") {
        const hasta = filter.find((fil: any) => fil.title === "Hasta");
        sessionStorage.setItem(
          activeFilter,
          JSON.stringify(
            Moment(hasta["product_insured_user.date_insured"]["$lte"])
              .add(1, "day")
              .format("DD/MM/YYYY")
          )
        );
      } else {
        sessionStorage.setItem(activeFilter, JSON.stringify(true));
      }
    });

    const updatedFilter = filter
      .map((filtro: any) => {
        let { title, ...rest } = filtro;
        rest["$or"]?.length === 1 && (rest = rest["$or"][0]);
        rest["$or"]?.length === 0 && (rest = null); // Set rest to null if $or length is 0
        return rest;
      })
      .filter((rest: any) => rest !== null); // Filter out null values from the array
    return updatedFilter;
  };

  function applyFilter() {
    setFilterApplied(true);
    setIncludedMenu(false);
    console.log("lo que recibo en apply filter");
    console.log(filter);
    setFinalFilter(reformFilterArray());
    setIsChanged(false);
  }

  return (
    <>
      <div className="tableFilters-filters">
        <div className="tableFilters-filters-excluded">
          {props.formData?.excluded &&
            props.formData?.excluded?.filters?.map((a: any) => (
              <div
                className="tableFilters-filters-excluded-textInputWrapper"
                key={a.title}
              >
                <CustomTextInput
                  id="text filter input"
                  placeholder={a.title}
                  onChange={(e: any) => handleSearchTextChange(e.target.value)}
                  value={textFilter ? textFilter : ""}
                  lupa="true"
                  loading={localTextLoader ? "true" : null}
                />
              </div>
            ))}
        </div>
        {props.formData?.included && (
          <div className="tableFilters-filters-included">
            <div
              className="tableFilters-filters-included-main"
              onClick={() => setIncludedMenu(!includedMenu)}
            >
              <span
                className="material-symbols-rounded bold"
                style={{ fontSize: 18 }}
              >
                {props.formData?.included?.icon}
              </span>

              <p className="tableFilters-filters-included-main-title">
                {props.formData?.included?.text?.toUpperCase()}
              </p>
            </div>
            {includedMenu && (
              <div className="tableFilters-filters-included-menu">
                <div className="tableFilters-filters-included-menu-title">
                  <p className="tableFilters-filters-included-menu-title-text">
                    Filtros
                  </p>
                  <span
                    className="material-symbols-rounded bold medium"
                    onClick={() => handleClose()}
                    style={{ cursor: "pointer", fontSize: 20 }}
                  >
                    close
                  </span>
                </div>
                <div className="tableFilters-filters-included-menu-list">
                  {props.formData?.included?.filters?.map((a: any) => (
                    <div
                      className="tableFilters-filters-included-menu-list-item"
                      key={a.title}
                    >
                      <div className="tableFilters-filters-included-menu-list-item-line"></div>
                      <p className="tableFilters-filters-included-menu-list-item-title">
                        {a.title}
                      </p>
                      <div
                        className="tableFilters-filters-included-menu-list-item-opcionList"
                        style={{
                          flexDirection:
                            a.contatenation === "$or" ? "column" : "row",
                        }}
                      >
                        {a.elements?.map((b: any) => (
                          <OpcionComponent
                            key={b.text}
                            data={b}
                            type={a.type}
                            opcionUpdated={(value: any) =>
                              addToFilter(a.title, value, b.text)
                            }
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tableFilters-filters-included-menu-aplicar">
                  <MainButton
                    id="APLICAR"
                    text="APLICAR"
                    sinFondo
                    disabled={!isChanged}
                    onPress={() => applyFilter()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <OrdenarPor
          itemSelected={(a: any) => handleSorting(a)}
          sortList={props.chartData?.visualization?.sorts}
          default={
            props.chartData?.visualization?.sorts?.filter(
              (a: any) => a.default
            )[0]?.name
          }
        />
        {props.canDownload && (
          <div
            className="tableFilters-filters-download"
            onClick={() => props.download(setDownloading)}
          >
            {downloading ? (
              <span className="tableFilters-filters-loader"></span>
            ) : (
              <ReactSVG src={DownloadIcon} />
            )}
            <p className="tableFilters-filters-download-text">
              &nbsp;{downloading ? "DESCARGANDO" : "DESCARGAR"}
            </p>
            <span className="tableFilters-filters-download-tooltiptext">
              Vas a descargar un archivo .csv con las búsquedas/filtros que
              hayas aplicado.
            </span>
          </div>
        )}
        {props.canDownloadTxt && (
          <div
            className="tableFilters-filters-downloadTxt"
            onClick={() => {}}
          >
            <img className="tableFilters-filters-downloadTxt-text" src={txtImage}/>
            <span className="tableFilters-filters-downloadTxt-tooltiptext">Descargar como .txt</span>
          </div>
        )}
      </div>
      <div className="tableFilters-chips">
        {filterApplied &&
          activeFilterNames.map((filterName: string, index: any) => (
            <ChipComponent
              key={index + filterName}
              text={filterName}
              onClose={(event) => handleChipClose(event, index)}
            />
          ))}
        {filterApplied && activeFilterNames.length !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "2px",
            }}
            onClick={() => window.location.reload()}
          >
            <p className="borrar-filtros-text">Borrar filtros</p>
          </div>
        )}
      </div>
    </>
  );
};

export default TableFilters;
