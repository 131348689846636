import React, { useState } from "react";
import "./CheckBoxComponent.css";

const CheckBoxComponent = (props: any) => {
  const [localValue, setLocalValue] = useState<boolean>(() => {
    const storedValue = sessionStorage.getItem(props.data?.text);
    return storedValue === null ? false : true;
  });

  return (
    <div className="CheckBoxComponent-container">
      <div
        className="CheckBoxComponent-container-box"
        onClick={() => {
          setLocalValue(!localValue);
          props.changeValue(props.data?.filter);
        }}
      >
        <div
          className="CheckBoxComponent-container-box-check"
          style={{ transform: localValue ? `scale(${1})` : `scale(${0})` }}
        >
          <span className="material-symbols-rounded" style={{ fontSize: 10 }}>
            done
          </span>
        </div>
      </div>
      <p className="CheckBoxComponent-container-text regular medium">
        {props.data.text}
      </p>
    </div>
  );
};

export default CheckBoxComponent;
