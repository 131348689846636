import React, { useState } from 'react'
import './CopyComponent.css'
import { ReactComponent as Copy } from '../../../assets/images/svgs/contentCopy.svg';
import {ReactComponent as Ok} from '../../../assets/images/svgs/ok.svg';

const CopyComponent = (props: any) => {
const [isCopied, setIsCopied] = useState(false);

const handleIconClick=()=>{
    setIsCopied(true);
}

    return (
        <div className='copyComponent-container' onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave} onClick={handleIconClick}>
                <div className="copyComponent-container-tooltip">
                    {!isCopied ? <span className="copyComponent-container-tooltiptext">Copiar contenido</span> : <span className="copyComponent-container-tooltiptext">Copiado !</span>}
                </div>
                {!isCopied ? <Copy style={{ marginLeft: 10 }}/> : <Ok style={{ marginLeft: 10 }}/>}
        </div>
    );
}

export default CopyComponent;       
